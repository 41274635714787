import React, { useState, useRef, useEffect } from 'react';
import Link from 'next/link';
import { ChevronRightIcon } from '@heroicons/react/solid';;

const Breadcrumbs = (props) => {
    const {
        breadcrumbs,
        categoryUrlSuffix,
        current,
        clearStorage,
        isCategory
    } = props;
    const [gradient, setGradient] = useState(true);

    const breadcrumbRef = useRef();

    useEffect(() => {
        const { clientWidth, scrollWidth } = breadcrumbRef.current;

        // if an overflow is present
        if (scrollWidth > clientWidth) {
            setGradient(true);
            setTimeout(() => {
                breadcrumbRef.current.scrollTo(scrollWidth, 0);
            }, 150);
        } else {
            setGradient(false);
        }

        const breadcrumbRefCopy = breadcrumbRef.current;

        return () => {
            breadcrumbRefCopy?.removeEventListener('scroll', addGradient);
            window.removeEventListener('resize', addGradient);
        };
    }, []);

    const addGradient = () => {
        if (breadcrumbRef.current) {
            const { scrollLeft, clientWidth, scrollWidth } =
                breadcrumbRef.current;

            // if we've scrolled 'till the end
            if (scrollLeft > 0) {
                setGradient(true);
            } else {
                setGradient(false);
            }
        }
    };

    breadcrumbRef.current?.addEventListener('scroll', addGradient);
    window.addEventListener('resize', addGradient);

    // Remove breadcrumb data from session storage, so we don't show it again
    if (clearStorage && typeof window !== 'undefined') {
        sessionStorage.removeItem('breadcrumb');
    }
    return (
        <div className="relative py-2 content-container">
            <div
                className={`${gradient ? 'opacity-1' : 'opacity-0'
                    } w-24 h-full absolute top-0 left-0 bg-gradient-to-r from-[#f3f3f3] transition-opacity duration-150 z-10 pointer-events-none`}
            />
            <div
                ref={breadcrumbRef}
                className="w-full overflow-y-auto hideScrollBar"
            >
                {breadcrumbs?.length > 0 ? (
                    <nav aria-label="Breadcrumb">
                        <ol
                            role="list"
                            className="mx-auto flex items-center space-x-1 lg:space-x-0"
                        >
                            <li key="Startseite">
                                <div className="flex items-center">
                                    <Link href="/">
                                        <a className="text-sm font-medium skin-text whitespace-nowrap py-1 px-2 bg-white rounded-full lg:bg-transparent lg:py-0 lg:px-0">
                                            Startseite
                                        </a>
                                    </Link>

                                    <div className="hidden lg:block">
                                        <ChevronRightIcon className="h-6 w-6" />
                                    </div>
                                </div>
                            </li>
                            {breadcrumbs.map((breadcrumb) => (
                                breadcrumb.category_name ?
                                    <li key={breadcrumb.id}>
                                        <div className="flex items-center">
                                            {isCategory ? (
                                                <Link
                                                    href={{
                                                        pathname: '[...pageUrl]',
                                                        query: {
                                                            pageUrl: `${breadcrumb.category_url_path +
                                                                categoryUrlSuffix
                                                                }`
                                                        }
                                                    }}
                                                    as={`/${breadcrumb.category_url_path +
                                                        categoryUrlSuffix
                                                        }`}
                                                >
                                                    <a className="text-sm font-medium skin-text whitespace-nowrap py-1 px-2 bg-white rounded-full lg:bg-transparent lg:py-0 lg:px-0">
                                                        {breadcrumb.category_name}
                                                    </a>
                                                </Link>
                                            ) : (
                                                <Link href={breadcrumb.category_url_path + categoryUrlSuffix}>
                                                    <a className="text-sm font-medium skin-text whitespace-nowrap py-1 px-2 bg-white rounded-full lg:bg-transparent lg:py-0 lg:px-0">
                                                        {breadcrumb.category_name}
                                                    </a>
                                                </Link>
                                            )}

                                            <div className="hidden lg:block">
                                                <ChevronRightIcon className="h-6 w-6" />
                                            </div>
                                        </div>
                                    </li> : <li key={breadcrumb.id}>
                                        <div className="flex items-center">
                                            <Link
                                                href={{
                                                    pathname: breadcrumb.url_path,
                                                    query: {
                                                        pageUrl: `${breadcrumb.url_path
                                                            }`
                                                    }
                                                }}
                                                as={`/${breadcrumb.url_path
                                                    }`}
                                            >
                                                <a className="text-sm font-medium skin-text whitespace-nowrap py-1 px-2 bg-white rounded-full lg:bg-transparent lg:py-0 lg:px-0">
                                                    {breadcrumb.name}
                                                </a>
                                            </Link>
                                            <div className="hidden lg:block">
                                                <ChevronRightIcon className="h-6 w-6" />
                                            </div>
                                        </div>
                                    </li>
                            ))}
                            <li
                                key={current}
                                className="text-sm flex text-[#454545] whitespace-nowrap"
                            >
                                <div className="py-1 px-2 bg-white rounded-full lg:bg-transparent lg:py-0 lg:px-0">
                                    {current}
                                </div>
                            </li>
                        </ol>
                    </nav>
                ) : (
                    <nav>
                        <ol className="mx-auto flex items-center space-x-1 lg:space-x-0">
                            <li
                                key="Startseite"
                                className="text-sx flex items-center"
                            >
                                <Link href="/" as="/">
                                    <a className="text-sm font-medium skin-text whitespace-nowrap bg-white rounded-full py-1 px-2 lg:bg-transparent lg:py-0 lg:px-0">
                                        Startseite
                                    </a>
                                </Link>{' '}
                                <div className="hidden lg:block">
                                    <ChevronRightIcon className="h-6 w-6" />
                                </div>
                            </li>

                            <li
                                key={current}
                                className="text-sm flex text-[#454545] whitespace-nowrap py-1 px-2 bg-white rounded-full lg:bg-transparent lg:py-0 lg:px-0"
                            >
                                {current}
                            </li>
                        </ol>
                    </nav>
                )}
            </div>
        </div>
    );
};
export default Breadcrumbs;
