import React from 'react';
import Breadcrumbs from '../Breadcrumbs';
import RichContent from '../RichContent/RichContent';
import CmsBlock from '../CmsBlock';
import { NextSeo } from 'next-seo';

export const CmsPage = (props) => {
    const { pageData } = props;
    //console.log('CMS Page API data: ', pageData);
    return (
        <React.Fragment>
            <NextSeo
                description={pageData.meta_description}
                title={pageData.meta_title}
            />
            <CmsBlock identifiers={['clothing_cateogory']} />
            <div className="w-full">
                <div>
                    {pageData.url_key != 'homepage-funkelhaus' ? (
                        <div className="bg-skin-site-secondary-bg">
                            <div className="self-start">
                                <Breadcrumbs current={pageData?.title} />
                            </div>
                        </div>
                    ) : null}
                    <div className="w-full">
                        <RichContent html={pageData?.content} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
